import Methods from '@/api/methods'
import UploadFile from '@/api/uploadFile'

export default {
  data() {
    return {
      confirmPassword     : null,
      errors              : {},
      // Rule check button clicked
      ruleCheckBtnClicked : false,
      // Password viewing
      passwordType        : 'password',
      passwordConfirmType : 'password',
      currentPasswordType : 'password'
    }
  },
  mounted() {
    Methods.addExternalScripts(this.scripts)
  },
  methods : {
    searchPostCode() {
      const params = {
        postCode : this.parent.registerData.postCode
      }
      this.$store.state.loading = true
      Methods.apiExcute('search-post-code', this.$i18n.locale, params).then(data => {
        this.$store.state.loading = false
        this.parent.registerData.address = (data.address1 || '') + (data.address2 || '') + (data.address3 || '')
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    validate() {
      console.log('validate')
      if (this.ruleCheckBtnClicked === false) {
        return
      }
      if (this.parent.mode === 'RegisterMember') {
        this.parent.registerData.files = this.parent.filesName.map(file => file.key)
      }
      const params = {
        memberRequestNo : this.parent.memberRequestNo || null,
        registerData    : this.parent.registerData,
        validateFlag    : true
      }
      this.errors = {}
      this.checkInputFormat()
      this.$store.state.loading = true

      console.log('params = ', params)
      this.request(params)
        .then(data => {
          if (Object.keys(this.errors).length === 0) {
          /*
           * This.uploadFiles()
           *   .then(() => {
           */
            this.$store.state.loading = false
            this.parent.editFlag = false
            window.scrollTo({
              top      : 0,
              left     : 0,
              behavior : 'smooth'
            })
            /*
             * })
             * .catch(error => {
             *   this.$store.state.loading = false
             *   console.log('アップロード失敗', error)
             * })
             */
          } else {
            this.$store.state.loading = false
          }
        })
        .catch(error => {
          console.log('error', error)
          this.$store.state.loading = false
          // 表示順のため
          let default_errs = {
            nickname         : null,
            nickname_dup     : null,
            name             : null,
            furigana         : null,
            type             : null,
            ownerAssociation : null,
            representative   : null,
            postCode         : null,
            address          : null,
            tel              : null,
            fax              : null,
            invoice          : null,
            email            : null,
            email_dup        : null,
            confirmEmail     : null,
            password         : null,
            confirmPassword  : null
          }
          default_errs = Object.assign(default_errs, this.errors, Methods.parseHtmlResponseError(this.$router, error))
          const tmp_errs = {}
          Object.keys(default_errs).map(x => {
            if (default_errs[x]) {
              tmp_errs[x] = default_errs[x]
            }
          })
          this.errors = Object.assign({}, tmp_errs)
        })
    },
    checkInputFormat() {
      console.log('checkInputFormat')
      let tags = [
        'nickname',
        'name',
        'furigana',
        'representative'
      ]

      if (this.parent.mode === 'RegisterMember') {
        tags = [
          'nickname',
          'name',
          'furigana',
          'representative',
          'address',
          'email'
        ]
      }

      const pattern = new RegExp('^[0-9a-zA-Z -/:-@\[-~]+$')
      const jpPattern = new RegExp('^([^\x01-\x7E]|[0-9a-zA-Z -/:-@\[-~])+$')
      // Const jpNicknamePattern = new RegExp('^([^\x01-\x7E]|[a-zA-Z0-9-_.])+$')
      this.errors = {}
      for (const tag of tags) {
        if (this.parent.registerData[tag]) {
          if (!jpPattern.test(this.parent.registerData[tag])) {
            this.errors[tag] = this.$t('MEMBER_REQUEST_TEXT_FORMAT_ERROR')
          }
        }
      }
      if (this.extraValidate) {
        this.extraValidate()
      }
    },
    addFile(file) {
      if (file.target.files.length > 0) {
        if (this.checkFileSize(file.target.files[0])) {
          this.errors = Object.assign({}, this.errors, {filesName : this.checkFileSize(file.target.files[0])})
        } else {
          this.parent.filesName.push(file.target.files[0])
          this.parent.registerData.files.push(file.target.files[0].name)
        }
      }
    },
    checkFileSize(file) {
      if (file && file.size) {
        // 2022.02.22: 制限が5MBにする
        if (file.size > 5 * 1024 * 1024) {
          return this.$t('MY_PAGE_UPDATE_FILE_SIZE_LIMIT_ERROR_MESSAGE')
        }
      }
      return null
    },
    uploadFiles() {
      return Promise.resolve()
        .then(() => {
          if (this.parent.exportFiles.filter(file => !file.key).length > 0 || this.parent.antiqueFiles.filter(file => !file.key).length > 0 || this.parent.nameCardFiles.filter(file => !file.key).length > 0) {
            return UploadFile.getUploadCredentials('member-request')
          } else {
            return Promise.resolve({})
          }
        })
        .then(credentials => {
          return Promise.all([
            Promise.all(this.parent.filesName.filter(file => !file.key).map((file, index) => {
              const s3access = credentials
                ? Object.assign({}, credentials, {
                  prefix_key : `${credentials.prefix_key}/attFiles/${index}`
                })
                : {}
              return UploadFile.upload(s3access, file)
                .then(result => {
                  file.key = result.Key
                  return Promise.resolve(result.Key)
                })
            }))
          ])
            .then(result => {
              this.parent.registerData.files = this.parent.filesName.length > 0 ? this.parent.filesName.map(file => file.key) : null
            })
        })
    },
    isJapanView() {
      return true
    },
    showHidePassword(type = 1) {
      switch (type) {
      case 1:
        this.currentPasswordType = this.currentPasswordType === 'password' ? 'text' : 'password'
        break
      case 2:
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
        break
      case 3:
        this.passwordConfirmType = this.passwordConfirmType === 'password' ? 'text' : 'password'
        break
      default:
        break
      }
    },
    ruleCheckClick() {
      // 更新ボタンの活性・非活性
      if (document.getElementById('rule-chk').checked === false) {
        console.log('uncheck')
        this.ruleCheckBtnClicked = false
      } else {
        console.log('checked')
        this.ruleCheckBtnClicked = true
      }
    }
  },
  watch : {
    errors(value) {
      const keys = Object.keys(value)
      if (keys.length > 0) {
        let refkey = keys[0]
        if (keys[0] === 'email_dup') {
          refkey = 'email'
        } else if (keys[0] === 'nickname_dup') {
          refkey = 'nickname'
        }
        if (value[keys[0]] && this.$refs[refkey]) {
          this.$refs[refkey].focus()
        }
      }
    }
  }
}
